<script setup>
  import { ref, computed, defineProps, reactive, nextTick, watchEffect  } from "vue";
  import { createPayLog, alipayForm } from '../api/index'
  import { ElMessage, ElLoading } from 'element-plus'
  import { useRouter } from "vue-router";
  import { useStore } from 'vuex'
  import { invoice, packageList } from "../api/company";
  import { ssoInfo } from "../api/index"
  import Bus from "../js/Bus";
  const router = useRouter()
  const store = useStore()
  const formRef = ref(null);
  const formRef1 = ref(null);
  const formLabelWidth = '80px'
  const payType = ref(0)
  const chargeType = ref(0)
  const receiveType = ref(1)
  const invoiceType = ref(0)
  const totalPrice = ref(0)
  const limitPrice = ref(0)
  const screenW = ref(0)
  const list = reactive({
    data: []
  })

  const form = reactive({
    money: null,
    packageId: 0,
    deductionAmount: null,
    ques: '',
  })
  const validatorMoney = (rule, value, callback) => {
    console.log(rule, value)  
      if (value <= 0) {
        return callback(new Error('金额需大于0'))
      }
      callback()
  };
  const formRules = {
    money: [
      { required: true, message: '金额不能为空', trigger: 'blur' },
      { validator: validatorMoney, trigger: 'blur' }
    ],
    ques: [{ required: true, message: '退款原因不能为空', trigger: 'blur' }],
    companyName: [{ required: true, message: '企业名称不能为空', trigger: 'blur' }],
    creditCode: [{ required: true, message: '企业税号不能为空', trigger: 'blur' }],
    bankName: [{ required: true, message: '开户行不能为空', trigger: 'blur' }],
    bankNumber: [{ required: true, message: '银行账户不能为空', trigger: 'blur' }],
    registerAddress: [{ required: true, message: '企业地址不能为空', trigger: 'blur' }],
    registerPhone: [{ required: true, message: '电话号码不能为空', trigger: 'blur' }],
    address: [{ required: true, message: '接收地址不能为空', trigger: 'blur' }],
    mail: [{ required: true, message: '电子邮箱地址不能为空', trigger: 'blur' }],
    receiveName: [{ required: true, message: '收件人不能为空', trigger: 'blur' }],
    receivePhone: [{ required: true, message: '联系电话不能为空', trigger: 'blur' }],
    personName: [{ required: true, message: '发票抬头不能为空', trigger: 'blur' }],
    personAddress: [{ required: true, message: '接收地址不能为空', trigger: 'blur' }],
    personMail: [{ required: true, message: '电子邮箱地址不能为空', trigger: 'blur' }],
    personReceiveName: [{ required: true, message: '收件人不能为空', trigger: 'blur' }],
    personReceivePhone: [{ required: true, message: '联系电话不能为空', trigger: 'blur' }],
  }
  const payLogId = computed({
    get () {
      return store.state.payLogId
    },
    set (val) {
      store.commit('updatePayLogId', val)
    }
  })
  const form1 = computed({
    get () {
      return store.state.invoice
    },
    set (val) {
      store.commit('updateInvoice', val)
    }
  })
  
  const show = computed({
    get () {
      return store.state.showAuth
    },
    set (val) {
      store.commit('updateAuth', val)
    }
  })

  const amount = computed({
    get () {
      return store.state.amount
    },
    set (val) {
      store.commit('updateAmount', val)
    }
  })

  const userInfo = computed({
    get () {
      return store.state.userInfo
    },
    set (val) {
      store.commit('updateUserInfo', val)
    }
  })

  const type = computed({
    get () {
      return store.state.type
    },
    set (val) {
      store.commit('authType', val)
    }
  })
  
  // const props = defineProps({
  //   show: {
  //     type: Boolean,
  //     default: false,
  //     required: true
  //   }
  // });
  // const emit = defineEmits(['sbumit', 'update:show'])
  const goAliPay = (res) => {
    // this.$router.replace({
    //   path: '/subscribe',
    //   query: {
    //     out_trade_no: res.data.payLog.orderNo,
    //     type: 0
    //   }
    // })
    console.log('xxxxxx')
    document.body.removeChild(document.getElementById('pay-box'))
  }
  
  const beforePay = () => {
    const loading = ElLoading.service({
      lock: true,
      text: '订单生成中...',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    let url = window.location.href
    if (url.includes('&charset')) {
      url = url.split('&charset')[0]
    }
    if (url.includes('?charset')) {
      url = url.split('?charset')[0]
    }
    console.log('returnUrl:', url)
    let params = {
      amount: form.money
    }
    if (chargeType.value === 1) {
      params.packageId = form.packageId
      params.deductionAmount = form.deductionAmount
    }
    if (type.value === 20) {
      params.chargeCompanyId = chargeCompanyId.value
    }
    createPayLog(params).then(ret => {
      closeModel()
      if (ret.data) {
        if (payType.value === 0) {
          alipayForm({
            payLogId: ret.data.id,
            returnUrl: url
          }).then(res => {
            console.log(res)
            const div = document.createElement('div');
            div.id = 'pay-box'
            div.innerHTML = res.data.form;
            document.body.appendChild(div);
            // setTimeout(() => {
              document.getElementById('pay-box').children[0].submit();
              loading.close()
              goAliPay(res)
            // }, 5000);
          })
        } else {
          console.log('wxPay');
        }
      } else {
        //余额支付
        getUserInfo()
        ElMessage.success('购买成功')
        loading.close()
        if (type.value === 20) {
          Bus.$emit('chargeDone', 1)
        }
      }
    }).catch(err => {
      loading.close()
    })
  }

  const submit = () => {
    console.log(type.value)
    //0 企业认证 1认证中 2充值 3退款理由 4退款申请等待 5开票申请 6开票申请等待 7提现 8提现审核 9 代理商认证 10代理商认证中
    if (type.value === 2 || type.value === 20) {
      formRef.value.validate((valid) => {
        // 不通过校验
        if (!valid) return
        beforePay()
      })
      return
    }
    if (type.value === 7) {
      formRef.value.validate((valid) => {
        // 不通过校验
        if (!valid) return
        closeModel()
      })
      return
    }
    if (type.value === 3) {
      closeModel()
      return
    }
    if (type.value === 5) {
      if (amount1.value <= 0) {
        return
      }
      formRef1.value.validate((valid) => {
        if (!valid) {
          return
        }
        let params = JSON.parse(JSON.stringify(form1.value))
        if (receiveType.value === 0) {
          delete params['mail']
        } else {
          delete params['address']
          delete params['receiveName']
          delete params['receivePhone']
        }
        if (invoiceType.value === 0) {
          delete params['bankName']
          delete params['bankNumber']
          delete params['registerAddress']
          delete params['registerPhone']
        }
        params.receiveType = receiveType.value
        params.invoiceType = invoiceType.value
        params.payLogId = payLogId.value
        params.type = companyType.value
        invoice(params).then(res => {
          ElMessage.success(res.message)
          Bus.$emit('invoiceDone', 1)
          store.commit('updateAuth', false)
        })
      })
      return
    }
    if (type.value === 4 || type.value === 6 || type.value === 1 || type.value === 8 || type.value === 10) {
      closeModel()
      return
    }
    if (type.value === 9) {
      closeModel()
      localStorage.removeItem('agentForm')
      localStorage.removeItem('agentForm1')
      localStorage.removeItem('agentStep')
      let baseUrl =  window.location.href.split('#')[0]
      let url = baseUrl + '#/agent-auth'
      window.open(url)
      // router.push({ 
      //   path: '/agent-auth'
      // })
      return
    }
    // emit('sbumit', 'xxxxx')
    closeModel()
    let baseUrl =  window.location.href.split('#')[0]
    console.log(baseUrl)
    let url = baseUrl + '#/company-auth'
    window.open(url)
    // router.push({ 
    //   path: '/company-auth'
    // })
  }

  const changePackage = item => {
    form.packageId = item.id
    let num = 100
    totalPrice.value = ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num)
    limitPrice.value = ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num)
    console.log(item.price, userInfo.value.agentLevel.discount, limitPrice.value);
    form.deductionAmount = null 
  }

  const inputChange = (value, type) => {
    let result =
      ("" + value) // 第一步：转成字符串
      .replace(/[^\d^\\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
      .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
    if (type === 'deductionAmount') {
      let num = 100
      if (amount.value * 1 >= totalPrice.value * 1) {
        if (result * 1 > totalPrice.value * 1) {
          result = Number(totalPrice.value)
        }
      } else {
        if (result * 1 > amount.value * 1) {
          result = Number(amount.value)
        }
      }
      limitPrice.value = (totalPrice.value * num - result * num)/num
    }
    form[type] = result
  }

  const closeModel = () => {
    if (formRef.value) {
      formRef.value.resetFields()
    }
    store.commit('updateAuth', false)
  }

  const cancel = () => {
    // emit('update:show', false)
    closeModel()
  }
  const beforeClose = (done) => {
    // emit('update:show', false)
    closeModel()
    done()
  }

  const invoiceTypeChange = (val) => {
    // console.log(val)
    // if (val * 1 === 1) {
    //   receiveType.value = 0
    // }
  }

  const getPackage = () => {
    packageList().then(res => {
      list.data = res.data
      if (list.data.length > 0) {
        let item = list.data[0]
        form.packageId = item.id
        let num = 100
        totalPrice.value = ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num)
        limitPrice.value = ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num)
      }
    })
  }

  const getUserInfo = (isCreate) => {
    ssoInfo().then(res => {
      if (res) {
        store.commit('updateUserInfo', res.data)
        store.commit('updateAmount', res.data.balance)
        store.commit('updatePackageList', res.data.packageList)
      }
    })
  }

  const companyType = ref(0)

  // const companyTypeChange = (val) => {
  //   console.log(val)
  //   if (val * 1 === 1) {
  //     companyType.value = 1
  //   }
  // }

  const amount1 = computed({ //充值金额
    get () {
      return store.state.amount1
    },
    set (val) {
      store.commit('updateAmount1', val)
    }
  })

  const orderAmount = computed({ //抵扣金额
    get () {
      return store.state.orderAmount
    },
    set (val) {
      store.commit('updateOrderAmount', val)
    }
  })

  const discountAmount = computed({ //抵扣金额
    get () {
      return store.state.discountAmount
    },
    set (val) {
      store.commit('updateDiscountAmount', val)
    }
  })

  const deductionAmount = computed({ //抵扣金额
    get () {
      return store.state.deductionAmount
    },
    set (val) {
      store.commit('updateDeductionAmount', val)
    }
  })

  const chargeCompanyId =  computed({ //充值客户id
    get () {
      return store.state.chargeCompanyId
    },
    set (val) {
      store.commit('updateChargeCompanyId', val)
    }
  })

  const chargeCompanyName =  computed({ //充值客户名称
    get () {
      return store.state.chargeCompanyName
    },
    set (val) {
      store.commit('updateChargeCompanyName', val)
    }
  })

  watchEffect(() => {
    screenW.value = window.screen.width
    console.log(window.screen.width);
    console.log('watchEffect ddd', show.value, type.value);
    if (show.value) {
      if (type.value === 2 || type.value === 20) {   
        console.log('充值');
        if (formRef.value) {
          formRef.value.clearValidate()
        }
        if (type.value === 20) {
          chargeType.value = 1
        }
        form.deductionAmount = null
        getPackage()
      }
      if (type.value === 5) {
        companyType.value = !userInfo.value.companyId ? 1 : 0
        if (formRef1.value) {
          formRef1.value.clearValidate()
        }
        invoiceType.value = 0
        receiveType.value = 1
        // form1
      }
    }
  })
</script>

<template>
  <div class="soft-dialog">
    <el-dialog :style="{width: screenW < 768 ? '100vw' : type === 5 ? '680px' : (type === 2 || type === 20) && chargeType === 1 ? '608px' : '490px'}" v-model="show"
    :title="type === 5 ? '确定开票信息' : type === 3 ? '申请退款' : (type === 2 || type === 20) ? '平台充值' : '提示'"
      :show-close="false"
      :beforeClose="beforeClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false">
        <div v-if="type === 0" class="auth">
          <el-icon size="56" color="#FFC300"><WarningFilled /></el-icon>
          <div class="auth-item">当前企业还未认证</div>
          <div class="auth-item">认证后可申请成为代理商</div>
        </div>
        <div v-else-if="type === 1" class="auth">
          <el-icon size="56" color="#FFC300"><Clock /></el-icon>
          <div class="auth-item">企业认证审核中, 请耐心等待</div>
          <div class="auth-item">一般1至3个工作日</div>
        </div>
        <div v-else-if="type === 2 || type === 20" class="auth">
          <el-form :model="form" :rules="formRules"
            ref="formRef">
            <el-form-item v-if="type === 2" label="充值类型">
              <el-radio-group v-model="chargeType">
                <el-radio :label="0">直接充值</el-radio>
                <el-radio :label="1">购买套餐</el-radio>
              </el-radio-group>
            </el-form-item>
            <div v-if="chargeType === 1">
              <div v-if="type === 20" style="text-align:left;font-size:16px;font-weight:bold;margin-bottom:24px">
                <span>为{{chargeCompanyName}}购买套餐</span>
              </div>
              <el-form-item label="选择套餐">
                <div class="package-list">
                  <div class="package-item"
                    @click="changePackage(item)"
                    :class="{'package-item-active': form.packageId === item.id}"
                    v-for="item in list.data"
                    :key="item.id">
                    <div class="name">{{item.name}}</div>
                    <div class="info">{{item.price}}元{{item.balance}}篇（{{(item.price * 1/(item.balance * 1)).toFixed(2)}}元/篇），有限期{{item.validityDays ? item.validityDays + '天' : '无限制'}}</div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="余额抵扣" :label-width="formLabelWidth">
                <div>
                  <div class="flex-start">
                    <el-input style="width: 170px;" @input="val => inputChange(val, 'deductionAmount')" v-model.trim="form.deductionAmount" />
                      <span class="max-amount">最多可抵扣{{ Number(amount) >= Number(limitPrice) ?  Number(limitPrice).toFixed(2) : Number(amount).toFixed(2)}}</span>
                  </div>
                  <div class="need-amount">
                    <div>需支付</div>
                    <div class="limit-price">{{Number(limitPrice).toFixed(2)}}</div>
                    <div class="discount" v-if="userInfo.agentLevel
                    && totalPrice !== 0
                    && userInfo.agentLevel.discount !== 1">（已享受代理商专属{{userInfo.agentLevel.discount * 10}}折优惠）</div>
                  </div>
                </div>
              </el-form-item>
            </div>
            <el-form-item v-else label="充值金额" prop="money" :label-width="formLabelWidth">
              <el-input @input="val => inputChange(val, 'money')" v-model.trim="form.money" />
            </el-form-item>
            <el-form-item v-if="limitPrice * 1 !== 0 || chargeType === 0" label="充值方式" :label-width="formLabelWidth">
              <div class="charge-type">
                <div class="type-item" @click="payType = 0" :class="{'type-active': payType === 0}">支付宝</div>
                <!-- <div class="type-item" @click="payType = 1" :class="{'type-active': payType === 1}">微信</div> -->
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="type === 3" class="auth tui">
          <el-form :model="form" :rules="formRules"
            ref="formRef">
            <el-form-item label=" " prop="ques" label-width="0">
              <el-input
                type="textarea" :rows="6" v-model="form.ques" placeholder="请输入退款原因"
              />
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="type === 4" class="auth">
          <el-icon size="56" color="#FFC300"><Clock /></el-icon>
          <div class="auth-item">退款申请审核中，请耐心等待</div>
          <div class="auth-desc">款项将退回至原支付渠道</div>
        </div>
        <div v-else-if="type === 5" class="auth kaipiao">
          <!-- <div style="margin:0 40px">
            <el-form-item label="客户类型" label-width="110px">
              <el-radio-group  v-model="companyType">
                <el-radio :label="0">企业</el-radio>
                <el-radio :label="1">个人</el-radio>
              </el-radio-group>
            </el-form-item>
          </div> -->
          <el-form :model="form1" :rules="formRules"
            ref="formRef1" label-width="110px" >
            <div style="margin-bottom: 24px;padding:0 0 0 10px">
              <div style="font-size:14px;font-weight:700;text-align:left;padding-bottom:10px">订单详情</div>
              <div style="background: #f7f7f7;padding:10px 0;border-radius:5px">
                <div style="text-align:left">
                  <span style="display:inline-block;width:98px;text-align: right;padding-right: 12px;">订单金额</span>
                  <span>{{orderAmount.toFixed(2)}}</span>
                </div>
                <div style="text-align:left;margin:12px 0 0">
                  <span style="display:inline-block;width:98px;text-align: right;padding-right: 12px;">代理商折扣</span>
                  <span>{{discountAmount.toFixed(2)}}</span>
                </div>
                <div style="text-align:left;margin:12px 0 0">
                  <span style="display:inline-block;width:98px;text-align: right;padding-right: 12px;">余额抵扣</span>
                  <span>{{deductionAmount.toFixed(2)}}</span>
                  <span v-if="deductionAmount > 0" style="margin:10px 0 0 10px;color:red">抵扣金额请在对应的充值订单申请开票</span>
                </div>
                <div style="text-align:left;margin:12px 0 0">
                  <span style="display:inline-block;width:98px;text-align:right;padding-right:12px;">可开票金额</span>
                  <span style="color:#FFC300">{{amount1.toFixed(2)}}</span>
                </div>
              </div>
            </div>
            <template v-if="companyType === 0">
              <el-form-item label="企业名称" prop="companyName">
                <el-input v-model="form1.companyName" disabled placeholder="请填写企业名称" />
              </el-form-item>
              <el-form-item label="企业税号" prop="creditCode">
                <el-input v-model="form1.creditCode" placeholder="请填写企业税号" />
              </el-form-item>
              <el-form-item label="发票类型">
                <el-radio-group v-model="invoiceType" @change="invoiceTypeChange">
                  <el-radio :label="0">增值税普通发票</el-radio>
                  <el-radio :label="1">增值税专用发票</el-radio>
                </el-radio-group>
              </el-form-item>
              <div v-if="invoiceType === 1">
                <el-form-item label="开户行" prop="bankName">
                  <el-input v-model="form1.bankName" placeholder="请填写开户行" />
                </el-form-item>
                <el-form-item label="银行账户" prop="bankNumber">
                  <el-input v-model="form1.bankNumber" placeholder="请填写银行账户" />
                </el-form-item>
                <el-form-item label="企业地址" prop="registerAddress">
                  <el-input v-model="form1.registerAddress" placeholder="请填写企业地址" />
                </el-form-item>
                <el-form-item label="电话号码" prop="registerPhone">
                  <el-input v-model="form1.registerPhone" placeholder="请填写电话号码" />
                </el-form-item>
              </div>
              <!-- <el-form-item label="接收方式">
                <el-radio-group v-model="receiveType">
                  <el-radio :label="0">纸质发票</el-radio>
                  <el-radio :label="1" :disabled="invoiceType === 1">电子发票</el-radio>
                </el-radio-group>
              </el-form-item> -->
              <el-form-item v-if="receiveType === 0" label="发票收件地址" prop="address">
                <el-input type="textarea" :rows="6" v-model="form1.address" placeholder="请填写发票接收地址" />
              </el-form-item>
              <el-form-item v-else label="邮箱地址" prop="mail">
                <el-input v-model="form1.mail" placeholder="请填写电子邮箱地址" />
              </el-form-item>
              <el-form-item v-if="receiveType === 0" label="收件人" prop="receiveName">
                <el-input  v-model="form1.receiveName" placeholder="请填写收件人" />
              </el-form-item>
              <el-form-item v-if="receiveType === 0" label="联系电话" prop="receivePhone">
                <el-input v-model="form1.receivePhone" placeholder="请填写联系电话" />
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item label="发票抬头" prop="personName">
                <el-input v-model="form1.personName" placeholder="请填写发票抬头" />
              </el-form-item>
              <!-- <el-form-item label="接收方式">
                <el-radio-group v-model="receiveType">
                  <el-radio :label="0">纸质发票</el-radio>
                  <el-radio :label="1" :disabled="invoiceType === 1">电子发票</el-radio>
                </el-radio-group>
              </el-form-item> -->
              <el-form-item v-if="receiveType === 0" label="发票收件地址" prop="personAddress">
                <el-input type="textarea" :rows="6" v-model="form1.personAddress" placeholder="请填写发票接收地址" />
              </el-form-item>
              <el-form-item v-else label="邮箱地址" prop="personMail">
                <el-input v-model="form1.personMail" placeholder="请填写电子邮箱地址" />
              </el-form-item>
              <el-form-item v-if="receiveType === 0" label="收件人" prop="personReceiveName">
                <el-input  v-model="form1.personReceiveName" placeholder="请填写收件人" />
              </el-form-item>
              <el-form-item v-if="receiveType === 0" label="联系电话" prop="personReceivePhone">
                <el-input v-model="form1.personReceivePhone" placeholder="请填写联系电话" />
              </el-form-item>
            </template>
          </el-form>
        </div>
        <div v-else-if="type === 6" class="auth">
          <el-icon size="56" color="#FFC300"><Clock /></el-icon>
          <div class="auth-item">办理中，请耐心等待</div>
          <!-- <div class="auth-desc">发票将发送至您指定地址或邮箱</div> -->
        </div>
        <div v-else-if="type === 7" class="auth tx">
          <el-form :model="form" :rules="formRules"
            ref="formRef">
            <el-form-item class="tixian-form" label="提现金额" prop="money" :label-width="formLabelWidth">
              <el-input-number
                v-model="form.money"
                :min="0"
                :controls="false"
                :step="0.1"
                controls-position="right"
              />
              <div class="large">最大</div>
            </el-form-item>
            <div class="tixian">允许提现金额<span>8888.88</span></div>
          </el-form>
        </div>
        <div v-else-if="type === 8" class="auth">
          <el-icon size="56" color="#FFC300"><Clock /></el-icon>
          <div class="auth-item">审核中，请耐心等待</div>
          <div class="auth-desc">款项将退回至原支付渠道</div>
        </div>
        <div v-else-if="type === 9" class="auth">
          <el-icon size="56" color="#FFC300"><WarningFilled /></el-icon>
          <div class="auth-item">当前企业还未成为代理商</div>
          <div class="auth-item">成为代理商后可使用本平台功能</div>
        </div>
        <div v-else-if="type === 10" class="auth">
          <el-icon size="56" color="#FFC300"><Clock /></el-icon>
          <div class="auth-item">代理商认证审核中，请耐心等待</div>
          <div class="auth-item">一般1至3个工作日</div>
        </div>
      <template #footer>
        <span class="dialog-footer">
          <div :class="{'add_disabled': type === 5 && amount1 === 0}" class="add_submit" @click="submit">{{ type === 9  ? '成为代理商' : type >= 1 ? '确定': '认证'}}</div>
          <div v-if="type !== 1 && type !== 4 && type !== 6 && type !== 8 && type !== 10" class="add_cancel" @click="cancel">{{ type === 9 ? '稍后申请' :type >= 2 ? '取消': '稍后认证'}}</div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="less">
.soft-dialog {
  .el-dialog {
    width: 490px;
    border-radius: 8px
  }
  .el-dialog__header {
    text-align: left;
    margin-right: 0;
    border-bottom: 1px solid #D8D8D8;
  }
  .el-checkbox {
    height: 31px;
  }
  .el-form {
    margin: 0 40px;
    .el-form-item {
      margin-bottom: 24px;
      .max-amount {
        margin-left: 8px;
        font-size: 16px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #FFC300;
      }
      .need-amount {
        margin-top: 24px;
        text-align: left;
        font-size: 18px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #1A1A1A;
        height: 23px;
        line-height: 23px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        div {
          font-size: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .limit-price {
          margin-left: 32px;
          font-size: 18px;
          font-weight: 700;
          color: #FFC300;
        }
        .discount {
          font-size: 14px;
          font-weight: 700;
          color: #EA0000;
        }
      }
    }
    .package-list {
      display: flex;
      flex-direction: column;
      .package-item {
        // width: 300px;
        height: 43px;
        background: #FFFFFF;
        border-radius: 4px;
        opacity: 1;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border: 1px solid #767676;
        cursor: pointer;
        .name {
          font-size: 14px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 700;
          color: #767676;
          margin-right: 8px;
        }
        .info {
          font-size: 12px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          color: #767676;
        }
      }
      .package-item + .package-item {
        margin-top: 8px;
      }

      .package-item-active {
        border: 1px solid #FFC300 !important;
        .name {
          color: #FFC300 !important;
        }
      }
    }
  }
  .el-input-number {
    width: 100%;
    .el-input__inner {
      text-align: left;
    }
    .el-icon {
      margin-bottom: 0 !important;
    }
  }
  .tx {
    .el-form {
      margin: 0 40px;
      .tixian-form {
        .el-form-item__content {
          display: flex;
          flex-wrap: nowrap;
          .large {
            position: absolute;
            right: 10px;
            flex: 1;
            height: 30px;
            line-height: 30px;
            width: 24px;
            font-size: 12px;
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: 400;
            color: #FFC300;
            cursor: pointer;
          }
        }
      }
    }
    .tixian {
      text-align: right;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #9E9E9E;
      span {
        margin-left: 15px;
        font-weight: 700;
        color: #FFC300;
      }
    }
  }
  .tui {
    .el-form {
      margin: 0 40px;
      .el-form-item {
        margin-bottom: 0px;
        .el-form-item__label:before {
          content: '';
        }
      }
    }
  }
  .kaipiao {
    padding-bottom: 0px !important;
  }
  .auth {
    padding: 22px 0 29px;
    .el-icon {
      margin-bottom: 12px;
    }
    .auth-item {
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #1A1A1A;
      line-height: 23px;
    }
    .auth-desc {
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #AAAAAA;
      margin-top: 8px;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #D8D8D8;
    padding: 24px;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .add_disabled {
      background: #bbb !important;
      cursor: no-drop !important;
      color: #1A1A1A !important;
    }
    .add_submit {
      width: 100px;
      height: 31px;
      line-height: 31px;
      background: #1A1A1A;
      border-radius: 50px;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #FFC300;
      text-align: center;
      cursor: pointer;
    }
    .add_cancel {
      width: 100px;
      height: 29px;
      line-height: 29px;
      border-radius: 50px 50px 50px 50px;
      opacity: 1;
      border: 1px solid #FFC300;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #FFC300;
      margin-left: 16px;
      text-align: center;
      cursor: pointer;
    }
  }
  .charge-type {
    display: flex;
  }
  .type-item {
    width: 118px;
    height: 53px;
    background: #FFFFFF;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #D8D8D8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .type-item + .type-item {
    margin-left: 13px;
  }
  .type-active {
    border: 1px solid #FFC300;
  }
}
@media screen and (max-width: 768px) {
  .soft-dialog {
    .el-dialog {
      width: 100vw;
      border-radius: 8px
    }
    .el-dialog__header {
      text-align: left;
      margin-right: 0;
      border-bottom: 1px solid #D8D8D8;
    }
    .el-checkbox {
      height: 31px;
    }
    .el-form {
      margin: 0 40px;
      .el-form-item {
        margin-bottom: 24px;
        .max-amount {
          margin-left: 8px;
          font-size: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          color: #FFC300;
        }
        .need-amount {
          margin-top: 24px;
          text-align: left;
          font-size: 18px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          color: #1A1A1A;
          height: 23px;
          line-height: 23px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          div {
            font-size: 16px;
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: 400;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .limit-price {
            margin-left: 32px;
            font-size: 18px;
            font-weight: 700;
            color: #FFC300;
          }
          .discount {
            font-size: 14px;
            font-weight: 700;
            color: #EA0000;
          }
        }
      }
      .package-list {
        display: flex;
        flex-direction: column;
        .package-item {
          // width: 300px;
          height: 43px;
          background: #FFFFFF;
          border-radius: 4px;
          opacity: 1;
          display: flex;
          align-items: center;
          padding: 0 16px;
          border: 1px solid #767676;
          cursor: pointer;
          .name {
            font-size: 14px;
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: 700;
            color: #767676;
            margin-right: 8px;
          }
          .info {
            font-size: 12px;
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: 400;
            color: #767676;
          }
        }
        .package-item + .package-item {
          margin-top: 8px;
        }

        .package-item-active {
          border: 1px solid #FFC300 !important;
          .name {
            color: #FFC300 !important;
          }
        }
      }
    }
    .el-input-number {
      width: 100%;
      .el-input__inner {
        text-align: left;
      }
      .el-icon {
        margin-bottom: 0 !important;
      }
    }
    .tx {
      .el-form {
        margin: 0 40px;
        .tixian-form {
          .el-form-item__content {
            display: flex;
            flex-wrap: nowrap;
            .large {
              position: absolute;
              right: 10px;
              flex: 1;
              height: 30px;
              line-height: 30px;
              width: 24px;
              font-size: 12px;
              font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
              font-weight: 400;
              color: #FFC300;
              cursor: pointer;
            }
          }
        }
      }
      .tixian {
        text-align: right;
        font-size: 16px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #9E9E9E;
        span {
          margin-left: 15px;
          font-weight: 700;
          color: #FFC300;
        }
      }
    }
    .tui {
      .el-form {
        margin: 0 40px;
        .el-form-item {
          margin-bottom: 0px;
          .el-form-item__label:before {
            content: '';
          }
        }
      }
    }
    .kaipiao {
      padding-bottom: 0px !important;
    }
    .auth {
      padding: 22px 0 29px;
      .el-icon {
        margin-bottom: 12px;
      }
      .auth-item {
        font-size: 16px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #1A1A1A;
        line-height: 23px;
      }
      .auth-desc {
        font-size: 16px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #AAAAAA;
        margin-top: 8px;
      }
    }
    .el-dialog__footer {
      border-top: 1px solid #D8D8D8;
      padding: 24px;
    }
    .dialog-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .add_submit {
        width: 100px;
        height: 31px;
        line-height: 31px;
        background: #1A1A1A;
        border-radius: 50px;
        font-size: 16px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #FFC300;
        text-align: center;
        cursor: pointer;
      }
      .add_cancel {
        width: 100px;
        height: 29px;
        line-height: 29px;
        border-radius: 50px 50px 50px 50px;
        opacity: 1;
        border: 1px solid #FFC300;
        font-size: 16px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #FFC300;
        margin-left: 16px;
        text-align: center;
        cursor: pointer;
      }
    }
    .charge-type {
      display: flex;
    }
    .type-item {
      width: 118px;
      height: 53px;
      background: #FFFFFF;
      border-radius: 4px;
      opacity: 1;
      border: 1px solid #D8D8D8;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .type-item + .type-item {
      margin-left: 13px;
    }
    .type-active {
      border: 1px solid #FFC300;
    }
  }
}
</style>