import { createStore } from 'vuex'
// 类似 Redux 中的建立状态树

export default createStore({
  
  // 1、 存储所有全局数据
  state: {
    showAuth: false,
    type: 0, //0 企业认证 1认证中 2充值 3退款 4退款申请等待 5开票申请 6开盘申请等待
    amount: 0,
    amount1: 0,
    orderAmount: 0,
    discountAmount: 0,
    deductionAmount: 0,
    packageList: [],
    payLogId: '',
    invoice: {
      companyName: '',
      creditCode: '',
      bankName: '',
      bankNumber: '',
      registerAddress: '',
      registerPhone: '',
      address: '',
      mail: '',
      receiveName: '',
      receivePhone: '',
      personName: '',
      personAddress: '',
      personMail: '',
      personReceiveName: '',
      personReceivePhone: '' 
    },
    userInfo: {},
    step: 0, //认证步骤
    domainUrl: '',
    chargeCompanyId: 0,
    chargeCompanyName: ''
  },
 
  // 2、 需要通过计算获取state里的内容获取的数据
  // 只能读取不可修改
  getters: {
   
  },
 
  //  3、定义对state的各种操作
  // why不直接实现在mutation里需要写到action里?
  // mtations不能执行异步操作。aq:从云端获取信息-->(等待云端反馈)更新到state异步操作
  // 因此说:对于异步操作需要放到action里，简单的直接赋值操作可以直接放到mutation里
  mutations: {
    updateAuth(state, data){
      console.log('修改', data)
      state.showAuth = data;
    },
    authType(state, data) {
      state.type = data;
    },
    updateAmount(state, data) {
      state.amount = data
    },
    updateAmount1(state, data) {
      state.amount1 = data
    },
    updateOrderAmount(state, data) {
      state.orderAmount = data
    },
    updateDiscountAmount(state, data) {
      state.discountAmount = data
    },
    updateDeductionAmount(state, data) {
      state.deductionAmount = data
    },
    updateInvoice(state, data) {
      state.invoice = data
    },
    updatePayLogId(state, data) {
      state.payLogId = data;
    },
    updateStep(state, data) {
      state.step = data;
    },
    updatePackageList(state, data) {
      state.packageList = data
    },
    updateUserInfo(state, data) {
      state.userInfo = data
    },
    updateDomainUrl(state, data) {
      state.domainUrl = data
    },
    updateChargeCompanyId(state, data) {
      state.chargeCompanyId = data
    },
    updateChargeCompanyName(state, data) {
      state.chargeCompanyName = data
    }
  },

  // 3、定义对state的各种操作
  // actions无法直接修改state，需要在mutations里更新
  // mutation不支持异步，所以需要在action里写api到url
  actions: {
      // 比说action定义了更新state的操作
      // 但是不可对其直接修改
      // 所有的修改操作必须放到mutations里
  },

  // state中信息过长时
  // 用来将state进行分割
  // 如下，将state树分割出一个user state。
  modules: {}
})

